<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
         {{ $t('Amazon Order') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'pre-alert' }" >
          {{ $t('Pre Alert') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{$route.params.id}}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'pre-alert' }"
    >
      <!-- <feather-icon icon="RotateCcwIcon" /> -->
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    <!-- first floor -->
    <b-row>
      <b-col
        md="6"
        lg="9"
      >
        <b-row>
          <b-col
            md="4"
            lg="3"
            class="ll-p2"
          >
            <b-card
              class="ll-c ll-4"
              :header="$t('MAWB No')"
              :title="mawbBaseInfo.mawb"
            >
            </b-card>
          </b-col>
          <b-col
            md="4"
            lg="3"
            class="ll-p2"
          >
             <b-card
              class="ll-c ll-4"
              :header="$t('Airline')"
              :title="mawbBaseInfo.airLine"
            >
            </b-card>
          </b-col>
          <b-col
            md="4"
            lg="3"
            class="ll-p2"
          >
            <b-card
              class="ll-c ll-4"
              :header="$t('Packages')"
              :title="mawbBaseInfo.totalQuantity"
            >
            </b-card>
          </b-col>
          <b-col
            md="4"
            lg="3"
            class="ll-p2"
          >
            <b-card
              class="ll-c ll-4"
              :header="$t('Charge')+'(USD)'"
              :title="mawbBaseInfo.totalCharge"
            >
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        md="6"
        lg="3"
      >
      <div class="ll-tagBox">
        <b-badge
          pill
          :variant="`light-${color}`"
          class="ll-badge"
          v-for="(item, index) of shipStatusList"
          :key="index">
          <span v-if="mawbBaseInfo.cusFinishTime !== null">{{ item }}</span>
        </b-badge>
      </div>
      </b-col>
    </b-row>
    <!-- second floor -->
    <b-row>
      <b-col
        md="6"
        lg="9"
        class="ll-p2"
      >
        <b-card
          no-body
          class="card-browser-states ll-6 ll-boxH"
        >
          <b-card-header>
            <div>
              <b-card-title>{{ $t('CUS Status') }}</b-card-title>
            </div>
            <div>
            <b-button
              v-b-toggle.sidebarCUS
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              @click="getTime"
            >
              <span>{{ $t('Edit') }}</span>
            </b-button>
            <!-- <b-button
              v-b-toggle.sidebarCUSExc
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
            >
              <span>Edit Exc</span>
            </b-button> -->
            </div>
          </b-card-header>
          <b-card-body>
            <b-table :items="CUSitems" :fields="CUSfields">
              <!-- <template #cell(updateCount)="row">
                <span class="updateCount">{{ row.value }}</span>
              </template> -->
              <template #cell(statusType)="scope">
                <svg class="ll-ficon" aria-hidden="true" v-if="scope.value==='NORMAL'">
                  <use xlink:href="#icon-form_icon_normal"></use>
                </svg>
                <svg class="ll-ficon" aria-hidden="true" v-if="scope.value==='EXCEPTION'">
                  <use xlink:href="#icon-form_icon_disable"></use>
                </svg>
                <svg class="ll-ficon" aria-hidden="true" v-if="scope.value==='MESSAGE'">
                  <use xlink:href="#icon-icon-test"></use>
                </svg>
              </template>
            </b-table>
          </b-card-body>
       </b-card>
      </b-col>
      <b-col
        md="6"
        lg="3"
        class="ll-p2"
      >
        <b-card
          no-body
          class="card-browser-states ll-c ll-boxH">
          <b-card-header>
            <div>
              <b-card-title>{{ $t('MAWB summary') }}</b-card-title>
            </div>
            <b-button
              v-b-toggle.sidebar1
              id="popover-reactive-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              v-if="false"
            >
              <span>{{ $t('Edit') }}</span>
            </b-button>
          </b-card-header>
          <b-card-body>
            <b-form-group
              class="ll-bform"
              :label="$t('Customer')"
              label-for="customer"
            >
              <b-form-input
                id="customer"
                v-model="mawbBaseInfo.customer"
                readonly
              />
            </b-form-group>
            <b-form-group
              class="ll-bform"
              :label="$t('Forwarder')"
              label-for="forwarder"
            >
              <b-form-input
                id="forwarder"
                v-model="mawbBaseInfo.forwarder"
                readonly
              />
            </b-form-group>
            <b-form-group
              class="ll-bform"
              :label="$t('Car Tag No')"
              label-for="carTagNo"
            >
              <b-form-input
                id="carTagNo"
                v-model="mawbBaseInfo.carTagNo"
                readonly
              />
            </b-form-group>
            <b-form-group
              class="ll-bform"
              :label="$t('Creation Date')"
              label-for="createdAt"
            >
              <b-form-input
                id="createdAt"
                v-model="mawbBaseInfo.createdAt"
                readonly
              />
            </b-form-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!-- third floor -->
    <b-row>
      <b-col
        md="6"
        lg="9"
        class="ll-p2"
      >
        <b-card
          no-body
          class="card-browser-states ll-r"
        >
          <b-card-header>
            <div class="ll-1">
              <b-card-title class="ll-title">{{ $t('Package TrackingID') }}</b-card-title>
              <b-form-input
                v-model="searchPID"
                :placeholder="$t('Search')"
                type="search"
                class="ll-input"
                @input="handleSearch"
              />
            </div>
          </b-card-header>
          <b-card-body>
            <!-- <b-table :items="items" :filter="searchPID"/> -->
            <b-table
              :items="items"
              :fields="Pfields"
            >
              <template #cell(isMatch)="row">
                <b-badge
                  pill
                  :variant="row.value==0 ? 'light-danger' : 'light-info'"
                  class="ll-badge">
                    <span>{{ row.value==0 ? 'No' : 'Yes' }}</span>
                </b-badge>
              </template>
            </b-table>
            <div class="d-flex justify-content-between flex-wrap ll-page">
              <div class="d-flex align-items-center mb-0 mr-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="size"
                  :options="pageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap"> of {{ totalSize }} entries </span>
              </div>
              <div>
                <b-pagination
                  v-model="page"
                  :total-rows="totalSize"
                  :per-page="size"
                  first-number
                  last-number
                  class="mb-0 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        md="6"
        lg="3"
        class="ll-p2"
      >
        <b-card
          no-body
          class="card-browser-states ll-c ll-6 ll-r"
        >
          <b-card-header>
            <div>
              <b-card-title>{{ $t('Events Timestamp') }}</b-card-title>
            </div>
            <b-button
              v-b-toggle.sidebar-right
              id="popover-reactive-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              @click="getTimeInfo"
            >
              <span>{{ $t('Edit') }}</span>
            </b-button>
          </b-card-header>
          <b-card-body>
            <b-row class="ll-h">
              <b-col class="ll-trim">{{ $t('Type') }}</b-col>
              <b-col class="ll-trim1">{{ $t('Time') }}</b-col>
            </b-row>
            <app-timeline>
            <b-row class="ll-3">
              <b-col>
                <app-timeline-item variant="warning" text="1">
                <div>
                  Arrival Notice Time
                  <i class="fa fa-question-circle-o fa-lg"
                    v-b-tooltip.hover.right= "ANTTips"
                  />
                </div>
                </app-timeline-item>
              </b-col>
              <b-col class="ll-t">
                {{ mawbBaseInfo.arrivalNoticeTime}}
              </b-col>
            </b-row>
            <b-row class="ll-3">
              <b-col>
                <app-timeline-item variant="secondary" text="2">
                <div>
                  ETD
                  <i class="fa fa-question-circle-o fa-lg"
                    v-b-tooltip.hover.right= "ETDTips"
                  />
                </div>
                </app-timeline-item>
              </b-col>
              <b-col class="ll-t">
                {{ mawbBaseInfo.etdTime }}
              </b-col>
            </b-row>
            <b-row class="ll-3">
              <b-col>
                <app-timeline-item text="3">
                <div>
                  ETA
                  <i class="fa fa-question-circle-o fa-lg"
                    v-b-tooltip.hover.right= "ETATips"
                  />
                </div>
                </app-timeline-item>
              </b-col>
              <b-col class="ll-t">
                {{ mawbBaseInfo.etaTime }}
              </b-col>
            </b-row>
            <b-row class="ll-3">
              <b-col>
                <app-timeline-item variant="info" text="4">
                <div>
                  ATA
                  <i class="fa fa-question-circle-o fa-lg"
                    v-b-tooltip.hover.right= "ATATips"
                  />
                </div>
                </app-timeline-item>
              </b-col>
              <b-col class="ll-t">
                {{ mawbBaseInfo.ataTime }}
              </b-col>
            </b-row>
            <b-row class="ll-3">
              <b-col>
                <app-timeline-item variant="danger" text="5">
                <div>
                  Shipment Pickup Time
                  <i class="fa fa-question-circle-o fa-lg"
                    v-b-tooltip.hover.right= "SPTTips"
                  />
                </div>
                </app-timeline-item>
              </b-col>
              <b-col class="ll-t">
                {{ mawbBaseInfo.shipmentPickupTime }}
              </b-col>
            </b-row>
            <b-row class="ll-3">
              <b-col>
                <app-timeline-item variant="dark" text="6">
                <div>
                  Arrival SC Time
                  <i class="fa fa-question-circle-o fa-lg"
                    v-b-tooltip.hover.right= "ASTTips"
                  />
                </div>
                </app-timeline-item>
              </b-col>
              <b-col class="ll-t">
                {{ mawbBaseInfo.arrivalScTime }}
              </b-col>
            </b-row>
            </app-timeline>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        lg="9"
        class="ll-p2"
      >
       <b-card
          no-body
          class="card-browser-states ll-r"
          v-if="false"
        >
          <b-card-header>
            <div>
              <b-card-title>{{ $t('Charges') }}</b-card-title>
            </div>
            <b-button
              v-b-toggle.sidebarChange
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
            >
              <span>{{ $t('Edit') }}</span>
            </b-button>
          </b-card-header>
          <b-card-body>
            <b-table :items="chargesList" :borderless="borderless"/>
            <!-- <b-table
              :items="chargesList"
              :fields="fields"
              :borderless="borderless"
            >
            </b-table> -->
          </b-card-body>
       </b-card>
      </b-col>
      <b-col
        md="6"
        lg="3"
        class="ll-p2"
      >
      </b-col>
    </b-row>
    <!-- summary popover -->
    <b-sidebar id="sidebar1" title="Edit MAWB summary"
      right
      shadow
      bg-variant="white"
      backdrop>
      <div class="px-3 py-2">
        <b-form-group
          class="g-label"
          label="Customer"
          label-for="customer1"
        >
          <b-form-input
            id="customer1"
            v-model="mawbBaseInfo.customer"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="Forwarder"
          label-for="forwarder1"
        >
          <b-form-input
            id="forwarder1"
            v-model="mawbBaseInfo.forwarder"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="Car Tag No"
          label-for="carTagNo1"
        >
          <b-form-input
            id="carTagNo1"
            v-model="mawbBaseInfo.carTagNo"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="Creation Date"
          label-for="createdAt1"
        >
          <flat-pickr
            id="createdAt1"
            v-model="mawbBaseInfo.createdAt"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
          />
        </b-form-group>
        <div class="ll-btn">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            {{ $t('Save') }}
          </b-button>
        </div>
      </div>
    </b-sidebar>
    <!-- timestamp popover -->
    <b-sidebar id="sidebar-right" :title="$t('Edit')+$t('Timestamp')"
      right
      shadow
      bg-variant="white"
      backdrop>
      <div class="px-3 py-2">
        <b-form-group
          class="g-label"
          label="Arrival Notice Time"
          label-for="antime"
        >
          <flat-pickr
            id="antime"
            v-model="timesUpdate.arrivalNoticeTime"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="ETD"
          label-for="etdtime"
        >
          <flat-pickr
            id="etdtime"
            v-model="timesUpdate.etdTime"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="ETA"
          label-for="etatime"
        >
          <flat-pickr
            id="etatime"
            v-model="timesUpdate.etaTime"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="ATA"
          label-for="atatime"
        >
          <flat-pickr
            id="atatime"
            v-model="timesUpdate.ataTime"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="Shipment Pickup Time"
          label-for="sputime"
        >
          <flat-pickr
            id="sputime"
            v-model="timesUpdate.shipmentPickupTime"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="Arrival SC Time"
          label-for="ascTime"
        >
          <flat-pickr
            id="ascTime"
            v-model="timesUpdate.arrivalScTime"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
          />
        </b-form-group>
        <div class="ll-btn">
          <!-- <b-button
            v-b-toggle.sidebar-right
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mr-1"
            @click="onClose"
          >
            Cancel
          </b-button> -->
          <b-button
            variant="secondary"
            class="mr-1"
            @click="onClear2"
          >
            {{ $t('Clear') }}
          </b-button>
          <b-button
            v-b-toggle.sidebar-right
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="updateTime"
          >
            {{ $t('Save') }}
          </b-button>
        </div>
      </div>
    </b-sidebar>
    <!-- CUS popover -->
    <b-sidebar id="sidebarCUS" :title="$t('Add')+'/'+$t('Edit')+$t('CUS Status')"
      right
      shadow
      bg-variant="white"
      backdrop>
      <div class="px-3 py-2">
        <div class="ll-wbox">
          <div class="ll-box">
            <span> {{$t('Step')}} 1:</span>
            <b-form-group
              class="g-label"
              :label="$t('Enter CUS start status')+':'"
              label-for="cusStartTime"
            >
              <flat-pickr
                id="cusStartTime1"
                v-model="cusStart"
                class="form-control"
                @input="updatecusTime()"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
              />
            </b-form-group>
          </div>
          <div class="ll-box" >
            <span> {{$t('Step')}} 2:</span>
            <b-form-group
              class="g-label"
              :label="$t('Enter CUS finish status')+':'"
              label-for="cusFinishTime1"
            >
              <flat-pickr
                id="cusFinishTime1"
                v-model="cusFinish"
                @input="updatecusTime1()"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
              />
            </b-form-group>
          </div>
          <div class="ll-box">
            <p>{{$t('Remark')}}</p>
            <b-form-textarea
              placeholder=""
              rows="3"
              v-model="cusRaw.remark"
            />
          </div>
        </div>
        <div id="showExept">
          <div class="ll-wbox">
            <p>{{$t('Optional')}} {{$t('Enter CUS exception status for TKID')}}:</p>
            <b-form-textarea
              placeholder=""
              rows="3"
              v-model="tkIdList"
            />
          </div>
          <div class="ll-wbox">
            <p>{{$t('Optional')}} {{$t('Choose to an exception')}}:</p>
            <div class="ll-box">
              <v-select
                :options="expList"
                label="statusDesc"
                placeholder="Please select"
                class="ll-select"
                @input="code => getExcCode(code)"
              ></v-select>
              <flat-pickr
                v-model="cusExcRaw.eventTime"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
              />
            </div>
          </div>
          <div class="ll-wbox">
            <p>{{$t('Optional')}} {{$t('Remark')}} </p>
            <b-form-textarea
              placeholder=""
              rows="3"
              v-model="cusExcRaw.remark"
            />
          </div>
          <div class="ll-btn">
            <b-button
              variant="secondary"
              class="mr-1"
              @click="onClear"
            >
              {{ $t('Clear') }}
            </b-button>
            <b-button
              v-b-toggle.sidebarCUS
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click.prevent="PreventUpdateCUS"
            >
              {{ $t('Save') }}
            </b-button>
          </div>
        </div>
      </div>
    </b-sidebar>
    <!-- CUS EXC popover -->
    <b-sidebar id="sidebarCUSExc" title="CUS Status Exception"
      right
      shadow
      bg-variant="white"
      backdrop>
      <div class="px-3 py-2">
        <div id="showExept">
        <div class="ll-wbox">
          <p>(Optional) Enter CUS exception status for TKID:</p>
          <b-form-textarea
            placeholder=""
            rows="3"
            v-model="tkIdList"
          />
        </div>
        <div class="ll-wbox">
          <p>Choose to an exception:</p>
          <div class="ll-box">
            <v-select
              :options="expList"
              label="statusDesc"
              placeholder="Please select"
              class="ll-select"
              @input="code => getExcCode(code)"
            ></v-select>
            <flat-pickr
              v-model="cusExcRaw.eventTime"
              class="form-control"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
            />
          </div>
        </div>
        <div class="ll-wbox">
          <p>Remark</p>
          <b-form-textarea
            placeholder=""
            rows="3"
            v-model="cusExcRaw.remark"
          />
        </div>
        </div>
        <div class="ll-btn">
          <b-button
            variant="secondary"
            class="mr-1"
            @click="onClear1"
          >
            Clear
          </b-button>
          <b-button
            v-b-toggle.sidebarCUSExc
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="updateCUSExc"
          >
            Save
          </b-button>
        </div>
      </div>
    </b-sidebar>
    <!-- change popover -->
    <b-sidebar id="sidebarChange" title="Edit Change"
      right
      shadow
      bg-variant="white"
      backdrop>
      <div class="px-3 py-2">
        <b-form-group
          class="g-label"
          label="feeType"
          label-for="feeType1"
        >
          <b-form-input
            id="feeType1"
            v-model="chargesList.feeType"
            :value="chargesList.feeType"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="Total Amount"
          label-for="totalAmount1"
        >
          <b-form-input
            id="totalAmount1"
            v-model="chargesList.totalAmount"
            :value="chargesList.totalAmount"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="Date Charged"
          label-for="dateCharged1"
        >
          <b-form-input
            id="dateCharged1"
            v-model="chargesList.dateCharged"
            :value="chargesList.dateCharged"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="Operator"
          label-for="operator1"
        >
          <b-form-input
            id="operator1"
            v-model="chargesList.operator"
            :value="chargesList.operator"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="Invoice No"
          label-for="invoiceNo1"
        >
          <b-form-input
            id="invoiceNo1"
            v-model="chargesList.invoiceNo"
            :value="chargesList.invoiceNo"
          />
        </b-form-group>
        <div class="ll-btn">
          <b-button
            v-b-toggle.sidebarChange
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            Save
          </b-button>
        </div>
      </div>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BButton, VBTooltip, BTable, BSidebar, VBToggle,
  BCardHeader, BCardTitle, BCardBody, BFormTextarea, BBreadcrumb, BBreadcrumbItem, BPagination,
  BFormSelect, BBadge, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BTable,
    flatPickr,
    BSidebar,
    AppTimeline,
    AppTimelineItem,
    BFormTextarea,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BBreadcrumb,
    BBreadcrumbItem,
    BPagination,
    BFormSelect,
    BBadge,
    BImg,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    color: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      mawbBaseInfo: {
        airLine: '',
        arrivalNoticeTime: '',
        arrivalScTime: '',
        ataTime: '',
        carTagNo: '',
        createdAt: '',
        cusFinishTime: '',
        cusStartTime: '',
        customer: '',
        customsInspection: '',
        etaTime: '',
        etdTime: '',
        forwarder: '',
        handoverTime: '',
        lateArrival: '',
        mawb: '',
        shipmentPickupTime: '',
        updatedAt: '',
        totalQuantity: '',
        totalCharge: '',
      },
      timesUpdate: {
        mawb: '',
        arrivalNoticeTime: '',
        etdTime: '',
        etaTime: '',
        ataTime: '',
        shipmentPickupTime: '',
        arrivalScTime: '',
      },
      timesList: ['Arrival Notice Time', 'ETD', 'ETA', 'ATA', 'Shipment Pickup Time', 'Arrival SC Time'],
      timestamp: '2021-1-22 13:33',
      ANTTips: 'Receipt of notification from SF',
      ETDTips: 'Eestimated Time of Departure from original airport (US)',
      ETATips: 'Eestimated Time of Arrival to destinaton airport (HK)',
      ATATips: 'Actual Time of Arrival',
      SPTTips: 'Shipments are picked up by driver',
      ASTTips: 'Shipments are delivered to sort center',
      // fields: ['feeType', 'Amount', 'Date charged', 'Operator', 'Invoice No'],
      fields: ['feeType', 'totalAmount', 'dateCharged', 'operator', 'invoiceNo'],
      items: [],
      Pfields: [
        { key: 'trackingID', label: this.$t('Package Tracking ID') },
        { key: 'createdAt', label: this.$t('Timestamp') },
        { key: 'isMatch', label: this.$t('Matched') },
        { key: 'inBoundTime', label: this.$t('Inbound time') },
      ],
      searchPID: '',
      chargesList: [
        {
          feeType: 'GAT', totalAmount: '4.55', dateCharged: '', operator: '',
        },
      ],
      borderless: true,
      input1: '',
      input1state: null,
      input2: '',
      input2state: null,
      input1Return: '',
      input2Return: '',
      popoverShow: false,
      exeList: [
        { name: 'Delayed due to commercial good', value: '' },
        { name: 'Delayed due to customs inspection', value: '' },
      ],
      raw: {
        mawbs: '',
        page: '1',
        size: '30',
        orderBy: 'id',
        sequence: 'asc',
      },
      packages: '',
      mawbsParam: {
        mawbs: [],
      },
      cusRaw: {
        mawb: '',
        isAllPackage: true,
        eventCode: '',
        eventTime: '',
        remark: '',
      },
      CUSitems: [],
      CUSfields: [
        { key: 'statusType', label: '' },
        { key: 'statusNo', label: this.$t('Status No') },
        { key: 'eventDescription', label: this.$t('Status Desc') },
        // { key: 'eventCode', label: 'Status Desc' },
        { key: 'updateCount', label: this.$t('Qty') },
        { key: 'eventTime', label: this.$t('Timestamp') },
        { key: 'operator', label: this.$t('Operator') },
        { key: 'remark', label: this.$t('Remark') },
      ],
      showExept: false,
      expList: [],
      cusExcRaw: {
        mawb: '',
        isAllPackage: false,
        eventCode: '',
        eventTime: '',
        remark: '',
        tkIdList: [],
      },
      tkIdList: '',
      pageOptions: [10, 20, 30],
      page: null, // 当前页码
      size: 10, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      ptId: {
        mawb: '',
        page: '',
        size: '',
        // trackingID: '',
      },
      shipStatusList: ['CUS completed'],
      cusStart: '',
      cusFinish: '',
    }
  },
  watch: {
    input1(val) {
      if (val) {
        this.input1state = true
      }
    },
    input2(val) {
      if (val) {
        this.input2state = true
      }
    },
    tkIdList(val) {
      if (val !== '') {
        this.cusFinish = ''
      }
    },
  },
  created() {
  },
  mounted() {
    this.queryMawb()
    // this.queryCharges()
    this.queryPackageID()
    this.searchEvent()
    this.getExpList()
  },
  methods: {
    // mawb query
    queryMawb() {
      const mNo = this.$route.params.id
      this.raw.mawbs = mNo.split()
      // console.log(this.raw.mawbs)
      this.$http.post('/prealert/search', this.raw).then(res => {
        const info = res.data.data.content[0]
        // console.log('1', info)
        this.mawbBaseInfo = info
        this.mawbBaseInfo.totalCharge = (info.totalCharge !== null) ? info.totalCharge.toString() : ''
        this.mawbBaseInfo.totalQuantity = (info.totalQuantity !== null) ? info.totalQuantity.toString() : ''
      })
    },
    // charges query
    queryCharges() {
      this.$http.get('/charges/query', { params: { batchNo: this.$route.params.id } }).then(res => {
        console.log(res.data.content)
        // this.chargesList = res.data.content
      })
    },
    getPackageID() {
      this.$http.get('/prealert/findOrderisMatchByMawb', { params: this.ptId }).then(res => {
        const result = res.data.data
        // console.log(result)
        this.items = result.content
        this.page = result.page
        this.size = result.size
        this.totalSize = result.totalSize
        this.totalPage = result.totalPage
      })
    },
    // 获取Package TrackingID列表
    queryPackageID() {
      this.ptId.mawb = this.$route.params.id
      this.ptId.page = '1'
      this.ptId.size = '10'
      this.getPackageID()
    },
    // 根据TKID查询
    handleSearch() {
      if (this.searchPID.trim() !== '') {
        this.ptId.trackingID = this.searchPID.trim()
        this.getPackageID()
      } else {
        this.ptId = {
          mawb: this.$route.params.id,
          page: '1',
          size: '10',
        }
        this.getPackageID()
      }
    },
    handleChangePage(page) {
      this.ptId.mawb = this.$route.params.id
      this.ptId.page = page
      this.ptId.size = '10'
      this.getPackageID()
    },
    handlePageChange(active) {
      this.ptId.mawb = this.$route.params.id
      this.ptId.page = '1'
      this.ptId.size = active
      this.getPackageID()
    },
    editTimestamp() {
      // console.log('11')
    },
    onClose() {
      this.popoverShow = false
    },
    onOk() {
      if (!this.input1) {
        this.input1state = false
      }
      if (!this.input2) {
        this.input2state = false
      }
      if (this.input1 && this.input2) {
        this.onClose()
        // Return our popover form results
        this.input1Return = this.input1
        this.input2Return = this.input2
      }
    },
    onShow() {
      // This is called just before the popover is shown
      // Reset our popover form variables
      this.input1 = ''
      this.input2 = ''
      this.input1state = null
      this.input2state = null
      this.input1Return = ''
      this.input2Return = ''
    },
    // 查询CUS事件Code
    searchEvent() {
      const mawbNo = this.$route.params.id
      const mawbNos = mawbNo.split(/[(\r\n)\r\n]+/)
      this.mawbsParam.mawbs = mawbNos
      this.$http.post('/prealert/searchEvent', this.mawbsParam).then(res => {
        // console.log('2', res.data.data.content)
        const sd = res.data.data.content
        if (sd.length !== 0) {
          const data = res.data.data.content[0]
          // this.cusRaw.isAllPackage = data.isAllPackage
          this.cusRaw.eventCode = data.eventCode
          this.cusRaw.eventTime = data.eventTime
          this.cusRaw.remark = data.remark
          this.CUSitems = res.data.data.content
        }
      })
    },
    // 修改时间戳
    updateTime() {
      this.timesUpdate.mawb = this.$route.params.id
      this.$http.post('/prealert/updateTimestamp', this.timesUpdate).then(res => {
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Edit Success',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.queryMawb()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    PreventUpdateCUS() {
      if (this.cusFinish > this.cusStart || (this.cusStart !== '' && this.cusFinish === '')) {
        this.updateCUS()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'CUS finish time cannot earlier than CUS start',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    editCUS() {
      this.$http.post('/prealert/updateEvent', this.cusRaw).then(res => {
        if (res.data.code === '200') {
          this.searchEvent()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'cusStartTime edit success',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.queryMawb()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    // add/update cus status
    updateCUS() {
      // exc order
      if (this.tkIdList.trim() !== '') {
        this.cusFinish = ''
        this.cusExcRaw.mawb = this.$route.params.id
        this.cusExcRaw.tkIdList = this.tkIdList.trim().split(/[(\r\n)\r\n]+/)
        this.editCUSExc()
      } else {
        // cusStartTime有值提交一次
        if (this.cusStart !== '' && this.cusStart !== this.mawbBaseInfo.cusStartTime) {
          this.cusRaw.mawb = this.$route.params.id
          this.cusRaw.eventCode = 'CUSTOMS_CLEARANCE_DECLARING_HK'
          this.cusRaw.eventTime = this.cusStart
          this.editCUS()
        }
        // 判断
        if (this.cusStart === '' && this.cusFinish !== '') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please enter CUS start event first',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        } else if (this.cusFinish !== '' && this.cusFinish !== this.mawbBaseInfo.cusFinishTime) {
          // cusFinistTime有值提交一次
          this.cusRaw.mawb = this.$route.params.id
          this.cusRaw.eventCode = 'CUSTOMS_CLEARANCE_SUCCESS_HK'
          this.cusRaw.eventTime = this.cusFinish
          this.editCUS()
        }
      }
    },
    // 切换cus exeption
    ShowExep() {
      this.showExept = !this.showExept
    },
    // 获取CUS EXC 类型
    getExpList() {
      const obj = {}
      obj.eventOperationCode = ['CUSTOMS_CLEARANCE']
      obj.statusType = ['EXCEPTION']
      this.$http.post('/statusManagement/search', obj).then(res => {
        // console.log(res.data.data.content)
        const desc = res.data.data.content
        this.expList = desc
      })
    },
    // 获取cus intCode
    getExcCode(code) {
      this.cusExcRaw.eventCode = code.intCode
    },
    editCUSExc() {
      this.$http.post('/prealert/updateEvent', this.cusExcRaw).then(res => {
        if (res.data.code === '200') {
          this.searchEvent()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    // add/edit cus exc
    updateCUSExc() {
      if (this.tkIdList.trim() !== '') {
        this.cusExcRaw.mawb = this.$route.params.id
        this.cusExcRaw.tkIdList = this.tkIdList.trim().split(/[(\r\n)\r\n]+/)
        this.editCUSExc()
      }
    },
    onClear() {
      this.cusStart = ''
      this.cusFinish = ''
      this.cusRaw.remark = ''
      this.cusExcRaw.remark = ''
      this.cusExcRaw.eventTime = ''
      this.tkIdList = ''
    },
    onClear1() {
      this.cusExcRaw.remark = ''
      this.cusExcRaw.eventTime = ''
      this.tkIdList = ''
    },
    onClear2() {
      this.timesUpdate = {}
    },
    updatecusTime() {
    },
    updatecusTime1() {
    },
    getTime() {
      this.cusStart = this.mawbBaseInfo.cusStartTime
      this.cusFinish = this.mawbBaseInfo.cusFinishTime
    },
    getTimeInfo() {
      this.timesUpdate.arrivalNoticeTime = this.mawbBaseInfo.arrivalNoticeTime
      this.timesUpdate.etdTime = this.mawbBaseInfo.etdTime
      this.timesUpdate.etaTime = this.mawbBaseInfo.etaTime
      this.timesUpdate.ataTime = this.mawbBaseInfo.ataTime
      this.timesUpdate.shipmentPickupTime = this.mawbBaseInfo.shipmentPickupTime
      this.timesUpdate.arrivalScTime = this.mawbBaseInfo.arrivalScTime
    },
  },
}
</script>
<style scoped>
.ll-0{
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.mb-0{
  font-size: 12px;
}
.ll-1{
  display: flex;
  width: 100%;
}
.ll-title{
  width: 50%;
  margin: auto;
}
.ll-input{
  width: 50%;
}
.ll-p2{
  padding: 0.5rem;
}
.ll-c{
  margin-bottom: 0;
}
.ll-c .card-header{
  font-weight: 500;
  font-size: 1.285rem;
}
/* .ll-c .card-body{
  padding: 0.5rem;
  text-align: center;
} */
.ll-c .card-body .card-title{
  font-weight: normal;
  /* font-size: 14px; */
}
.d-block{
  font-size: 14px;
}
.ll-h .col{
  /* text-transform: uppercase; */
  font-size: 0.857rem;
  letter-spacing: 0.5px;
  font-weight: bold;
}
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-3{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.g-label{
  justify-content: space-between !important;
}
.ll-4{
  height: 80px;
  background-color: unset;
  box-shadow: unset;
}
.ll-4 .card-header{
  font-weight: normal;
  font-size: 12px;
}
/* .ll-4 .card-body{
  text-align: center;
} */
.ll-4 .card-title{
  font-weight: 500 !important;
  font-size: 16px;
}
.ll-bform{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ll-6{
  /* min-height: 274px; */
  margin-bottom: 0px;
}
.ll-trim{
  position: relative;
  left: 40px;
}
.ll-trim1{
  position: relative;
  /* left: 5px; */
  text-align: center;
}
.ll-box span{
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 20px;
}
.ll-wbox, .ll-select{
  margin-bottom: 15px;
}
.updateCount{
  display: inline-block;
  min-width: 30px;
  text-align: right;
}
.ll-boxH{
  height: 260px;
  overflow-y: auto;
}
.ll-r{
  height: 500px;
  overflow-y: auto;
}
.ll-tagBox{
  padding: 4.6rem 0.5rem 0 0;
}
.ll-t{
  text-align: right;
}
.ll-page{
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
<style>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th{
  background-color: #fff;
}
[dir] .table thead th{
  border: none;
  padding: 5px;
}
.d-block{
  font-size: 14px;
}
[dir] .card .card-title {
  margin-bottom: 0.5rem;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 22%;
}
.d-block{
  font-size: 12px;
}
.b-sidebar-header strong{
  width: 100%;
  text-align: center;
}
.table td {
    padding: 0.72rem;
}
[dir=ltr] .timeline-item .timeline-item-icon[data-v-ffb3c5da]{
  width: 15px;
  height: 15px;
  left: -8px;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
</style>
